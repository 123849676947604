<!--积分规则-->
<!--<div class='PointRule'></div>-->
<template>
    <div class="PointRule" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select placeholder="请选择" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="bd in meta.deptGroup"
                                    :label="bd.name"
                                    :value="bd.code"
                                    :key="bd.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.member.pointRule.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.pointRule.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="机构组名称" width="200" prop="deptGroupName" />
                <el-table-column label="消费" width="120" prop="money">
                    <template slot-scope="scope">
                        <span>{{ scope.row.money | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="积分" width="120" prop="point" />
                <el-table-column label="有效时间（年）" width="150" prop="effectiveTime" />
                <el-table-column label="积分清零结束日期" width="150" prop="ruleEndTime" />
                <el-table-column label="创建人" width="150" prop="creator" />
                <el-table-column label="创建时间" width="200" prop="createTime" />

                <el-table-column label="操作" width="145" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.pointRule.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.member.pointRule.edit')"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import MoneyUtils from '../../../js/MoneyUtils';
import UrlUtils from '../../../js/UrlUtils';

export default {
    name: 'PointRule',
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                deptGroup: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/member/pointRule/page',
            },
        };
    },
    mounted() {
        this.inits();
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        inits() {
            const _this = this;
            UrlUtils.DeptGroup(_this, (rst) => {
                _this.meta.deptGroup = rst;
            });
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '积分规则', '/member/pointRule/export', this.form, codes);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.member.pointRule.detail', ['会员管理', '会员积分规则', '会员积分规则详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.member.pointRule.edit', ['会员管理', '会员积分规则', '编辑会员积分规则'], {
                form: row,
                code: row.code,
            });
        },
    },
};
</script>

<style scoped>
.PointRule .el-form-item {
    margin-bottom: 0;
}
</style>
